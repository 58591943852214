
.setting-container{
    
       display: flex;
        flex-wrap: wrap;
        max-width: 500px;
        margin: 100px auto;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        background-color: var(--body_background);
        
}
.list{
    list-style: none;
}


