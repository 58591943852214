.RegisterFormClass{
  background-image: url("../images/compback.svg");
  background-repeat: no-repeat;
 
    max-width: 700px;
    margin: 30px auto;
    padding: 70px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border-radius: 5px;
    background-color: var(--body_background);
}
 
  .RegisterFormClass input {
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
  }
  h3{
    text-align: center;
  }
  .comp__image{
    width:500px;
    height: 500px;
    margin-left: 60px;
    
    transform: translate(-10%, -10%);
    animation: moveUpDown 2s infinite;
  }
  @keyframes moveUpDown {
    0%, 100% {
      transform: translate(-10%, -10%);
    }
    50% {
      transform: translate(-10%, -15%);
    }
  }

  .compform{
   
    
    display: flex;
    align-items: center;
   
  }
  
  .btn3{
    
    padding: 10px 20px;
    background-color: #1f7ad4;
    color:white;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    width: fit-content;
    
  }
  .heading{
    padding: 10px;
  }

  
  
  @media (max-width: 768px) {
    
  .compform{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    width:350px
   
  }
  

    .RegisterFormClass {
      /* Styles specific to screens with a maximum width of 768px */
      /* Adjust the form layout and styling as needed */
  
      /* Example styles: */
      width: 80%; /* Adjust the width as desired */
      margin: 0 auto; /* Center the form horizontally */
    }
      /* Example styles for the input fields */
      .input {
        width: 60%;
        margin-bottom: 10px;
      }
  
      /* Example styles for the submit button */
      button[type='submit'] {
        width: 100%;
      }
    
  }
 
