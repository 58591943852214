.navbar1 {
  background-color: var(--body_background);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid rgb(97, 95, 95);
  
}

.info{
  padding-left: 20px;
  font-weight: bold;
  background-color:#1f7ad4;
}
.logo {
  height: 80px;
  cursor: pointer;
  
  transform: translate(-10%, -10%);
  animation: moveUpDown 2s infinite;
 }
 @keyframes moveUpDown {
  0%, 100% {
    transform: translate(-10%, -10%);
  }
  50% {
    transform: translate(-10%, -15%);
  }
 }
.person{
  height: 40px;
  
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 110px;
  width: 60%;
}

.nav-container a{
  color: var(--body_color);
  font-weight: bold;
  font-size: smaller;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
} 

.nav-container ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 10;
  padding: 0;
}
.nav-container li {
  margin: 0 5px;
  position: relative;
}

li {
  position: relative;
}

li:hover .dropdown-content {
  display: block;
}

a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 16px;
  padding: 10px;
}

a:hover {
  color: #1f7ad4;
}

.image-setting{
  max-width: 40px;
}
.menu-icon {
  font-size: 28px;
  cursor: pointer;
}

.mobile-menu {
  display: block;
 
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid #ccc;
}

.mobile-menu ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-menu li {
  margin: 5px 0;
}

.mobile-menu a {
  font-size: 20px;
  
    color: var(--body_color);
    text-decoration: none;
    padding: 10px;
    display: inline-block;
  
}

.dropdown {
  position: relative;
}

.dropdown-menu.hidden {
  display: none;
}
.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: var(--body_background);
  color: var(--body_color);  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border: 1px solid #ccc;
  padding: 10px 0;
  top: 100%;
  left: 0;
}

.dropdown-content a {
  background-color: var(--body_background);
  color: var(--body_color);  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #1f7ad4;
  color:white;
}

.icon {
  margin-right: 10px;
}

.dropdown-toggle{
  margin: 10px;
}

.dropdown-menu {
  flex-direction: column;
  position: absolute;
  top: 90%;
  left: 90%;
  padding: 0;
  margin: 0;
  list-style:armenian ;
  
  
}

 .dropdown-menu {
  display: block;
}




@media(max-width: 767px){

  .dropdown-menu {
    flex-direction: column;
    
    top: 90%;
    left: 0%;
   
    list-style:armenian ;
    background-color: #fff;
    
  }
  .dropdown-content a:hover {
    background-color: #1f7ad4;
    color:white;
  }

}

.btn{
  width: 100%;
}
.subcategoriesList{
  display: flex;
  flex-direction: column;
  width: 100%;
}
