.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-item .page-link {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #1f7ad4;;
    cursor: pointer;
  }
  
  .page-item.active .page-link {
    background-color: #1f7ad4;
    color: #fff;
  }