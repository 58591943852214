.tenders-list {
  display: flex;
  background-color: var(--body_background);
  color: var(--body_color);
  
}
.ad-container {
  width: 20%;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.deadline{
  font-weight:normal;
}
.btn2{
  padding: 10px 20px;
  background-color: #1f7ad4;
  color:white;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  width: fit-content;

  
}

.tenders-container {
  background-color: var(--body_background);
  color: var(--body_color);
  word-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
}

@media(max-width: 767px){
  .tenders-list{
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    
  }
  .ad-container {
    width: 70%;
    margin-top: 10px;
  }
  .card{
    width:100%;
    flex-direction: column;
    word-wrap: break-word;
   
  }
  .card-wrapper {
  width: 100%;
    word-wrap: break-word;
    }
  }


.card {
  background-color: var(--body_background);
  color: var(--body_color);

  width:90%;
  flex-direction: column;
 
 border: 1px solid #ccc;
  margin: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  flex-direction: column;
  word-wrap: break-word;
}
.cardAlt{
  background-color: var(--body_background);
  color: var(--body_color);

  width:70%;
  flex-direction: column;
  justify-content: center;

 border: 1px solid #ccc;
 margin: 12px;
  margin-left: 13%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  flex-direction: column;
  word-wrap: break-word;
}
.tenders-container {
  word-wrap: break-word;
  flex-direction: column;
}

.card-wrapper {
  background-color: var(--body_background);
  color: var(--body_color);
  word-wrap: break-word;
  margin: 10px;
  padding: 1px;
  width: 100%;
  }

.ad2{
  margin-top: 30px;
}
.ad1{
  margin-top: 30px;
}

.button-container {
  display: flex;
}

.button-container button {
  margin-right: 10px;
}

.ad-image {
  animation-name: slideIn;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  padding: 10%;
}

@keyframes slideIn {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
