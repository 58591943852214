.Search_Tender{
 
    display: flex;
   margin-top: 10px;
   flex-direction: column;
}
/* .search-results{
   
} */

.searchh{
   width: 90%;
   margin-top: 20px;
}
@media(max-width: 767px){
   
.Search_Tender{
   
   display: flex;
   margin:10px
 }
    }
