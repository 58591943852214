.company-card {
    background-color: lightblue;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: calc(33.33% - 20px); /* Adjust the width as needed */
    margin-right: 20px; /* Add margin between cards */
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
  }
  
  .company-card h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }
  
  .company-card p {
    font-size: 14px;
    color: #555555;
  }

  @media (max-width: 768px) {
    .company-card {
      width: 100%; /* Make cards stack vertically on smaller screens */
      margin-right: 0;
      margin-bottom: 20px; /* Add margin between cards */
    }
  }