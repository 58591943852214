footer {
  position: static;
  background-color: var(--background_color);
  color: var(--body_color) ;
  padding: 40px 0;
  border-top: 1px solid rgb(97, 95, 95);;
  margin-top: 10px;
  font-size: 15px;
  
}

.content {
  display: flex;
  flex-wrap: wrap;
 
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 15px;
}

.links {
  font-size: 15px;
  flex-direction: column;
  
}
.phonebook button{
  background-color: #1f7ad4;
}
.links a {
  position: inherit;  
  
  text-decoration: none;
  color: var(--body_color) ;


}

.phonebook {
  margin: 0 20px;
}

.phonebook h3 {
  margin-bottom: 10px;
  font-size: 20px;
}

.phonebook ul {
  list-style: none;
  padding: 0;
}



.phonebook li:last-child {
  margin-bottom: 20px;
}


.phonebook a {
  text-decoration: none;
  color: var(--body_color);
}

.phonebook a:hover {
  text-decoration: underline;
}

.subscribe {
  margin: 0 20px;
  
}

.subscribe h3 {
  margin-bottom: 10px;
  font-size: 20px;
}

.subscribe p {
  margin-bottom: 20px;
}

.subscribe form {
  display: flex;
}

.subscribe input[type="email"] {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px 0 0 5px;
  margin-bottom: 10px;

}

.subscribe button[type="submit"] {
  padding: 10px 20px;
  background-color: #1f7ad4;
 
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
button{
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  background-color: #1f7ad4;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
button:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  color: #fff;
 
}
.social-media {
  margin: 0 20px;
}

.social-media h3 {
  margin-bottom: 10px;
  font-size: 20px;
}

.social-media ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.social-media li {
  margin-right: 10px;
}

.social-media a {
  color:var(--body_color);
  text-decoration: none;
}

.social-media a:hover {
  text-decoration: underline;
}

.social-media i {
  font-size: 24px;
}
.copyright{
   
   position: absolute;
    top : 55% ;
    left: 46% ;
}
.mss{
  text-align: center;
  margin-top: 50px;
}