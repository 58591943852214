.tender-form-container {
 
 
  display: flex;
  flex-wrap: wrap;
}
.tender__image{
 width: 100%;
 max-width: 400px;
 margin-right: 160px;
 transform: translate(-10%, -10%);
 animation: moveUpDown 2s infinite;
}
@keyframes moveUpDown {
 0%, 100% {
   transform: translate(-10%, -10%);
 }
 50% {
   transform: translate(-10%, -15%);
 }
}

.tender-form {
  background-image: url("../images/compback.svg");
  background-repeat: no-repeat;
  flex: 1;
  max-width: 500px;
  margin: 30px auto;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  
}

.select-label {
  margin-right: 10px;
  font-weight: bold;
}
.category-select{
  display: block;
  margin-bottom: 5px;
}
 
.select-dropdown {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
}




.form-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: light;
  margin-bottom: 5px;
  
  
}


input[type="text"],
textarea,
input[type="date"],
input[type="file"] {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}



@media (max-width: 768px) {
  .tender-form {
    flex-basis: 100%;
    margin-right: 0;
  }

  .select-dropdown {
   
  }
   
 
  
}